<template>
	<div>
		<v-row class="mot-survey-form-large">
			<v-col>
				<p class="text-subtitle-1 text-left">現在の主な勤務先を１つお選びください。<span class="star">*</span><br>図書館に勤務されている方は勤務先の館種を、それ以外の方は業種をお選びください。</p>
			</v-col>
		</v-row>
		<!-- <v-row class="mot-survey-form">
			<v-col>
				<v-select
					v-model="stateQ4"
					label="現在の主な勤務先"
					:items="occupations"
					item-text="text"
					item-value="value"
					:rules="[rules.required]"
					readonly
				></v-select>
			</v-col>
		</v-row> -->
		<v-row class="mot-survey-form">
			<v-col>
				<v-chip-group
					v-model="stateQ4"
					active-class="primary--text"
					column
				>
					<v-chip
						v-for="occupation in occupations"
						:key="occupation.value"
						:value="occupation.value"
						filter
					>
						{{ occupation.text }}
					</v-chip>
				</v-chip-group>
			</v-col>
		</v-row>
		<v-row class="mot-survey-form-large mot-survey-form-margin-bottom" v-if="stateQ4 === 99">
			<v-col>
				<v-text-field
					v-model="stateQ4other"
					placeholder="例：医療・福祉"
					:rules="[rules.max100chars, rules.required]"
					counter
					maxlength="100"
					rows="3"
				>
					<template v-slot:label>
						「その他」と答えた方は、業種をご記入ください。<span class="star">*</span>
					</template>
				</v-text-field>
			</v-col>
		</v-row>
	</div>
</template>

<style scoped>
.mot-survey-form-large {
	margin-top: 0em;
}

.mot-survey-form-small {
	margin-top: -1em;
}

.mot-survey-form-margin-bottom {
	margin-bottom: 0em;
}

.mot-survey-form {
	margin-top: -1em;
}

.note {
	font-size: 0.9em;
	color: #777;
}

.star {
	color: red;
	font-size: 1.2em;
	margin-left: 0.2em;
}

</style>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'UserSurveyQ4',
	data () {
		return {
			form: {
				occupation: null,
				other_occupation: ''
			},
			rules: {
				required: value => !!value || '必須',
				max100chars: value => value.length <= 100 || '最大100字',
			},
			occupations: [
				{ text: "公共図書館", value: 1 },
				{ text: "大学図書館", value: 2 },
				{ text: "学校図書館", value: 3 },
				{ text: "その他の図書館", value: 4 },
				{ text: "図書館関連企業（例：TRC、取次）", value: 5 },
				{ text: "出版", value: 6 },
				{ text: "書店", value: 7 },
				{ text: "情報（コンピュータ関連等）", value: 8 },
				{ text: "マスコミ", value: 9},
				{ text: "教育", value: 10 },
				{ text: "公務員・団体職員・行政", value: 11 },
				{ text: "博物館・美術館", value: 12 },
				{ text: "製造", value: 13 },
				{ text: "商社・流通", value: 14 },
				{ text: "不動産・リース", value: 15 },
				{ text: "サービス", value: 16 },
				{ text: "金融・保険", value: 17 },
				{ text: "その他（自由記述）", value: 99 }
			],
		}
	},
	computed: {
		...mapGetters({
			Q4: 'stateQ4',
			Q4other: 'stateQ4other'
		}),
		stateQ4: {
			get () {
				return this.Q4
			},
			set (value) {
				this.setQ4(value)
			}
		},
		stateQ4other: {
			get () {
				return this.Q4other
			},
			set (value) {
				this.setQ4other(value)
			}
		}
	},
	methods: {
		...mapActions([
			'setQ4',
			'setQ4other'
		]),
	}
}
</script>